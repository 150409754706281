<template>
  <section id="papi">
    <pibot-section-heading>
      <slot>PAPI - Parameters Affecting Pipeline Integrity</slot>
    </pibot-section-heading>

    <v-layout align-start
              justify-space-between
              wrap>
      <v-flex xs12
              md4
              v-for="(category) in categories"
              :key="category.type">
        <pibot-data-card
          :icon="category.icon"
          :title="category.title"
          :subTitle="category.subTitle"
          class="mx-3 my-4"
          v-if="groupedItems[category.type].length > 0"
        >
          <v-data-table
            dense
            disable-sort
            disable-filtering
            disable-pagination
            hide-default-footer
            :loading="asset === null"
            loading-text="Loading properties... Please wait"
            :headers="headers"
            :items="groupedItems[category.type]"
          >
            <template v-slot:[`item.property`]="{ item }">
              <div>{{item.property.title}}</div>
              <!-- <small v-if="item.property.description">{{item.property.description}}</small> -->
            </template>
          </v-data-table>
        </pibot-data-card>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
import SectionHeading from '@/components/typography/headings/SectionHeading'
import DataCard from '@/components/cards/DataCard'
import { assetMixin } from '../../../utils/mixins/asset.mixin'
import assetMetaMixin from '@/utils/mixins/meta/assetMeta.mixin'

export default {
  name: 'pibot-asset-papi',
  mixins: [assetMixin, assetMetaMixin],
  components: {
    'pibot-section-heading': SectionHeading,
    'pibot-data-card': DataCard
  },
  data () {
    return {
      categories: [
        {
          type: 'physical',
          icon: 'filter_tilt_shift',
          title: 'Physical properties',
          subTitle: ''
        },
        {
          type: 'operational',
          icon: 'build',
          title: 'Operational properties',
          subTitle: ''
        },
        {
          type: 'external',
          icon: 'terrain',
          title: 'External properties',
          subTitle: ''
        }
      ],
      headers: [
        {
          text: 'Property',
          value: 'property',
          align: 'start',
          sortable: false
        },
        {
          text: 'Value',
          value: 'value',
          align: 'start',
          sortable: false
        }
        // {
        //   text: 'Unit',
        //   value: 'unit',
        //   align: 'start',
        //   sortable: false
        // }
      ],
      groupedProps: null
    }
  },
  computed: {
    groupedItems () {
      const returnObj = {}

      for (const c of this.categories) {
        const { type } = c
        // Return an empty list when the asset is not yet available
        if (!this.asset) {
          returnObj[type] = []
          continue
        }

        // Return an empty list when the current type could not be found
        if (!this.groupedProps[type]) {
          returnObj[type] = []
          continue
        }

        // Map items
        const items = this.groupedProps[type].map(prop => {
          // Get meta object for prop
          const meta = this.getAssetMetaByKey(prop)

          if (!meta.title) meta.title = prop // Fallback to property key if no title was supplied

          // Map item Object
          return {
            property: {
              title: meta.title,
              description: meta.description
            },
            value: this.asset[prop],
            unit: meta.unit
          }
        })

        returnObj[type] = items
      }
      return returnObj
    }
  },
  watch: {
    assetsLoaded (assetsLoaded) {
      if (assetsLoaded && !this.groupedProps) this.groupedProps = this.groupByPapi(this.asset)
    }
  },
  created () {
    // Group props by PAPI category if not yet done
    if (!this.groupedProps) this.groupedProps = this.groupByPapi(this.asset)
  }
}
</script>
