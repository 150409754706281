export default {
  data () {
    return {
      assetMeta: {
        Name: {
          title: 'Pipeline Name',
          description: '',
          category: 'physical',
          unit: ''
        },
        Description: {
          title: 'Pipeline Description',
          description: '',
          category: 'physical',
          unit: ''
        },
        Product: {
          title: 'Service/Product',
          description: '',
          category: 'operational',
          unit: ''
        },
        // 'Service': {
        //   title: 'Service/Product',
        //   description: '',
        //   category: 'operational',
        //   unit: ''
        // },
        Length: {
          title: 'Length',
          description: '',
          category: 'physical',
          unit: '' // 'km'
        },
        Diam_out: {
          title: 'Outer Diameter',
          description: '',
          category: 'physical',
          unit: '' // 'mm'
        },
        Nominal_pipe_size: {
          title: 'Nominal pipe size',
          description: '',
          category: 'physical',
          unit: '' // 'mm'
        },
        Wt_nom: {
          title: 'Wall Thickness',
          description: '',
          category: 'physical',
          unit: ''
        },
        Nom_WT: {
          title: 'Nominal Wall Thickness',
          description: '',
          category: 'physical',
          unit: ''
        },
        Material: {
          title: 'Material',
          description: '',
          category: 'physical',
          unit: ''
        },
        Coating: {
          title: 'External Coating',
          description: '',
          category: 'physical',
          unit: ''
        },
        Coating_ext: {
          title: 'External Coating',
          description: '',
          category: 'physical',
          unit: ''
        },
        Coating_int: {
          title: 'Internal Coating',
          description: '',
          category: 'physical',
          unit: ''
        },
        Coating_field: {
          title: 'Field Coating',
          description: '',
          category: 'physical',
          unit: ''
        },
        Coating_weight: {
          title: '',
          description: '',
          category: 'physical',
          unit: ''
        },
        Coating_weight_t: {
          title: '',
          description: '',
          category: 'physical',
          unit: ''
        },
        W_coating: {
          title: 'Weight coating',
          description: '',
          category: 'physical',
          unit: ''
        },
        Field_coat: {
          title: 'Field coating',
          description: '',
          category: 'physical',
          unit: ''
        },
        D_temp: {
          title: 'Design temperature',
          description: '',
          category: 'operational',
          unit: ''
        },
        D_pressure: {
          title: 'Design pressure',
          description: '',
          category: 'operational',
          unit: ''
        },
        CP: {
          title: 'CP',
          description: '',
          category: 'physical',
          unit: ''
        },
        CP_type: {
          title: '',
          description: '',
          category: 'physical',
          unit: ''
        },
        CP_total: {
          title: '',
          description: '',
          category: 'physical',
          unit: ''
        },
        CP_spacing: {
          title: '',
          description: '',
          category: 'physical',
          unit: ''
        },
        Trenched: {
          title: '',
          description: '',
          category: 'operational',
          unit: ''
        },
        Total_risk: {
          title: 'Highest Risk',
          description: '',
          category: 'physical',
          unit: ''
        },
        Instal_date: {
          title: 'Construction year',
          description: '',
          category: 'physical',
          unit: ''
        },
        Installed: {
          title: 'Construction year',
          description: '',
          category: 'physical',
          unit: ''
        },
        Tag_no_: {
          title: 'Tag number',
          description: '',
          category: 'physical',
          unit: ''
        }
      }
    }
  },
  methods: {
    groupByPapi (properties) {
      const grouped = {}
      for (const key in properties) {
        // Find current property key in meta
        const meta = Object.keys(this.assetMeta).find(m => m === key)

        // No meta record found
        if (!meta) continue

        // Get corresponding category
        const { category } = this.assetMeta[meta]

        // Set default value if not yet available
        if (!Array.isArray(grouped[category])) grouped[category] = []

        // Add current property to the corresponding group
        grouped[category].push(meta)
      }
      return grouped
    },
    getAssetMetaByKey (key) {
      return this.assetMeta[key]
    }
  }
}
